@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  @apply bg-gray-100 m-0;
}

h1 {
  @apply font-semibold text-lg sm:text-xl;
}

h2 {
  @apply font-semibold text-2xl sm:text-3xl;
}

h3 {
  @apply font-semibold text-lg sm:text-xl;
}

label {
  @apply font-semibold;
}

.container {
  @apply mx-auto flex flex-col items-center py-5
}

button {
  @apply font-semibold py-2 px-4 rounded text-xl;
}

button.brand {
  @apply bg-[#23419B] !text-white;
}

button.brand:hover {
  @apply bg-blue-800;
}

ul {
  @apply list-none pl-0 space-y-2;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}